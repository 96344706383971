export const GoodsTypesColumn = [
  {
    title: '分类名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '父级名称',
    dataIndex: 'parent_name',
    key: 'parent_name',
  },
  {
    title: '是否显示',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
]

export const GoodsColumns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: '22%'
  },
  {
    title: '商品分类',
    dataIndex: 'types',
    key: 'types',
    scopedSlots: { customRender: 'types' },
    width: '15%'
  },
  {
    title: '库存状态-数量',
    dataIndex: 'stock',
    key: 'stock',
    scopedSlots: { customRender: 'stock' }
  },
  {
    title: '售卖方式',
    key: 'saleType',
    scopedSlots: { customRender: 'saleType' }
  },
  {
    title: '销售价',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: '积分值',
    dataIndex: 'creditPrice',
    key: 'creditPrice',
  },
  {
    title: '是否上架',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '操作',
    width: '10%',
    scopedSlots: { customRender: 'action' }
  }
]